import React, { useState } from "react"
import MessengerChat from "../components/messenger"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/common/header"
import { ParallaxProvider } from "react-scroll-parallax"
import { Row, Col, Container, Text, Button } from "atomize"
import firebase from "gatsby-plugin-firebase"
import { ModalSize } from "../components/formModal"

const Pricing = () => {
  const [showModal, setShowModal] = useState(false)
  const layout = "layout-2"
  return (
    <Layout>
      <MessengerChat />

      <React.Fragment>
        <SEO
          title="Bảng giá dịch vụ callbot gọi tự động uCall"
          description="uCall - Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
        />
        <meta
          property="og:title"
          content="Bảng giá dịch vụ callbot gọi tự động uCall"
        />
      </React.Fragment>
      <React.Fragment>
        <Header layout={layout} />
      </React.Fragment>

      <ParallaxProvider>
        <style jsx></style>
        <Row align={"center"}>
          <Col size={12}>
            <Container d="flex" flexDir="column" align="left">
              <div style={{ height: "128px" }} />
              <Text
                tag="h1"
                textWeight="700"
                textAlign="center"
                textSize="display3"
                fontFamily="secondary"
                textColor={"black"}
                m={{ b: "1rem" }}
              >
                Bảng Giá
              </Text>
              <div className="wrapper">
                <table>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Giọng cơ bản</td>
                      <td>Giọng nâng cao</td>
                      <td>Giọng ghi âm</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Giá nền tảng uCall</td>
                      <td colSpan={3}>600 đồng/phút</td>
                    </tr>
                    <tr>
                      <td>Giá cước viễn thông</td>
                      <td colSpan={3}>600 đồng/phút</td>
                    </tr>
                    <tr>
                      <td>Giá cá nhân hoá</td>
                      <td colSpan={3}>100 đồng/phút</td>
                    </tr>
                    <tr>
                      <td>
                        Giá cước viễn thông khi sử dụng đầu số tổng đài riêng
                        của doanh nghiệp
                      </td>
                      <td colSpan={3}>
                        KH trả trực tiếp cho nhà mạng, tuỳ thuộc vào nhà mạng
                        khách hàng đăng ký
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <center>Chi phí thực tế cuộc gọi được tính trên block 6s.</center>
              <div style={{ height: "64px" }} />

              <Text
                tag="h1"
                textWeight="700"
                textAlign="center"
                textSize="display3"
                fontFamily="secondary"
                textColor={"black"}
                m={{ b: "1rem" }}
              >
                Gói bán
              </Text>

              <div className="wrapper">
                <table>
                  <thead>
                    <tr>
                      <td>Giá</td>
                      <td>Khuyến mãi</td>
                      <td>Thời hạn</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>500,000 VNĐ</td>
                      <td>0%</td>
                      <td>1 tháng</td>
                    </tr>
                    <tr>
                      <td>1,000,000 VNĐ</td>
                      <td>5%</td>
                      <td>3 tháng</td>
                    </tr>
                    <tr>
                      <td>2,000,000 VNĐ</td>
                      <td>10% (200,000VNĐ)</td>
                      <td>6 tháng</td>
                    </tr>
                    <tr>
                      <td>5,000,000 VNĐ</td>
                      <td>15% (750,000VNĐ)</td>
                      <td>12 tháng</td>
                    </tr>
                    <tr>
                      <td>10,000,000 VNĐ</td>
                      <td>20% (2,000,000VNĐ)</td>
                      <td>18 tháng</td>
                    </tr>
                    <tr>
                      <td>20,000,000 VNĐ</td>
                      <td>25% (5,000,000VNĐ)</td>
                      <td>24 tháng</td>
                    </tr>
                    <tr>
                      <td>50,000,000 VNĐ</td>
                      <td>30% (15,000,000VNĐ)</td>
                      <td>24 tháng</td>
                    </tr>
                    <tr>
                      <td>100,000,000 VNĐ</td>
                      <td>35% (35,000,000VNĐ)</td>
                      <td>Không giới hạn</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Container>
          </Col>
        </Row>
        <div style={{ height: "128px" }} />
      </ParallaxProvider>
      <Container>
        <Text></Text>
      </Container>
    </Layout>
  )
}

export default Pricing
